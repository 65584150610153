import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Box, IconButton, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, InputAdornment, Link } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { login } from '../api';

const Login = ({ onLogin }) => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false); // state for modal

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await login(token);
      onLogin(data.player);
    } catch (error) {
      setError("Invalid token. Please try again.");
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: '#fff',
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography component="h1" variant="h5" color="primary">
          Login
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="token"
            label="Token"
            name="token"
            autoComplete="token"
            autoFocus
            value={token}
            onChange={(e) => setToken(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Skąd wziąć token?">
                    <IconButton onClick={handleDialogOpen}>
                      <HelpOutlineIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </Box>
      </Box>

      {/* Dialog for token information */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Skąd wziąć token?</DialogTitle>
        <DialogContent>
          <Typography sx={{ textAlign: 'center' }}>
            Jeżeli nie otrzymałeś swojego Tokenu wymaganego do łatwiejszego śledzenia swojego postępu w grze skontaktuj się z Robertem :)
            <br />
            <br />
            Discord: <Link href="https://discord.com/users/e_dn" target="_blank" rel="noopener noreferrer">e_dn</Link>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;
