import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, MenuItem, Select, InputLabel, FormControl, Typography, IconButton, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, Icon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DiscordIcon from '../assets/discord.svg';  // Importuj SVG jako komponent
import { submitFlag, getProfile } from '../api';
import { format } from 'date-fns';
import Flagi1 from '../assets/flagi1.png';
import Flagi2 from '../assets/flagi2.png';
import Panel1 from '../assets/panel1.png';
import Panel2 from '../assets/panel2.png';
import Survey1 from '../assets/survey1.png';
import Survey2 from '../assets/survey2.png';
import Zglaszanie1 from '../assets/zglaszanie1.png';
import Zglaszanie2 from '../assets/zglaszanie2.png';
import { CheckCircle, HourglassEmptyOutlined } from '@mui/icons-material';


const SubmitFlag = ({ onTaskUpdate }) => {
  // Stan komponentu i funkcje
  const [taskId, setTaskId] = useState('');
  const [flag, setFlag] = useState('');
  const [tasks, setTasks] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [cooldownTime, setCooldownTime] = useState(0);
  const [isOnCooldown, setIsOnCooldown] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [helpOpen, setHelpOpen] = useState(false);

  const fetchTasks = async () => {
    try {
      const profileData = await getProfile();
      const incompleteTasks = profileData.tasks.filter(task => !task.completed);
      setTasks(incompleteTasks);
    } catch (error) {
      console.error("Failed to fetch tasks", error);
    }
  };

  useEffect(() => {
    setStartDate('2024-10-28T12:00:00Z');
    setEndDate('2024-11-17T12:00:00Z');
    fetchTasks();
  }, []);

  useEffect(() => {
    let timer;
    if (showMessage || showError) {
      const endTime = Date.now() + 11000;
      timer = setInterval(() => {
        const remaining = endTime - Date.now();
        if (remaining <= -1000) {
          setShowMessage(false);
          setShowError(false);
          clearInterval(timer);
        } else {
          setProgress((remaining / 10000) * 102);
        }
      }, 100);
    }
    return () => clearInterval(timer);
  }, [showMessage, showError]);

  useEffect(() => {
    let timer;
    if (isOnCooldown && cooldownTime > 0) {
      timer = setInterval(() => {
        setCooldownTime(prevTime => {
          if (prevTime <= 1) {
            setIsOnCooldown(false);
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else if (!isOnCooldown && cooldownTime === 0) {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isOnCooldown, cooldownTime]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isOnCooldown) {
      return;
    }
    try {
      const data = await submitFlag(taskId, flag);
      setMessage(data.message);
      setError('');
      setShowMessage(true);
      setShowError(false);
      await fetchTasks();
      setTaskId('');
      setFlag('');
      if (onTaskUpdate) {
        onTaskUpdate();
      }
      if (isOnCooldown) {
        setIsOnCooldown(false);
        setCooldownTime(0);
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        const { detail } = error.response.data;
        const match = detail.match(/wait (\d+(\.\d+)?) seconds/);
        if (match) {
          const remainingTime = parseFloat(match[1]);
          setIsOnCooldown(true);
          setCooldownTime(remainingTime);
        }
      } else {
        setError("Submission failed. Please check the flag and try again.");
        setMessage('');
        setShowError(true);
        setShowMessage(false);
      }
      console.error("Failed to submit flag", error);
    }
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const handleCloseError = () => {
    setShowError(false);
  };

  const handleHelpOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 3 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Submit Flag
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: '100%', maxWidth: '500px' }}>
        <FormControl fullWidth margin="normal">
          <InputLabel id="task-select-label">Task</InputLabel>
          <Select
            labelId="task-select-label"
            id="task-select"
            value={taskId}
            onChange={(e) => setTaskId(e.target.value)}
            label="Task"
            required
            disabled={isOnCooldown}
          >
            {tasks.map((task) => (
              <MenuItem key={task.task_id} value={task.task_id}>
                {task.task_id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="flag"
          label="Flag"
          name="flag"
          autoComplete="flag"
          value={flag}
          onChange={(e) => setFlag(e.target.value)}
          disabled={isOnCooldown}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 2, mb: 2 }}
          disabled={isOnCooldown}
        >
          Submit Flag
        </Button>
        {isOnCooldown && (
          <Typography color="textSecondary">
            Please wait {Math.ceil(cooldownTime)} seconds before trying again.
          </Typography>
        )}
        {showMessage && (
          <Box sx={{ position: 'relative', width: '100%', maxWidth: '500px', mt: 2 }}>
            <Typography color="success">{message}</Typography>
            <IconButton
              onClick={handleCloseMessage}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 1 }}
            />
          </Box>
        )}
        {showError && (
          <Box sx={{ position: 'relative', width: '100%', maxWidth: '500px', mt: 2 }}>
            <Typography color="error">{error}</Typography>
            <IconButton
              onClick={handleCloseError}
              sx={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ mt: 1 }}
            />
          </Box>
        )}
      </Box>

      {/* Wyświetlanie dat CTF */}
      {startDate && endDate ? (
        <Typography variant="body1" sx={{ mt: 3 }}>
          CTF is running from {format(new Date(startDate), 'dd/MM/yyyy')} to {format(new Date(endDate), 'dd/MM/yyyy')}.
        </Typography>
      ) : (
        <Typography variant="body1" sx={{ mt: 3 }}>
          Dates for the CTF are not available.
        </Typography>
      )}

      {/* Ikony pod datami */}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 3 }}>
        {/* Ikona pytajnika */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleHelpOpen}>
          <HelpOutlineIcon fontSize="large" />
        </IconButton>
        </Box>

        {/* Ikona maila */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton href="mailto:264138@student.pwr.edu.pl">
          <MailOutlineIcon fontSize="large" />
        </IconButton>
        <Typography variant="body1" sx={{ ml: 1 }}>264138@student.pwr.edu.pl</Typography>
        </Box>

        {/* Ikona Discorda */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton href="https://discord.com/users/e_dn" target="_blank">
            <img src={DiscordIcon} alt="Discord Icon" style={{ width: '24px', height: '24px' }} />
          </IconButton>
          <Typography variant="body1" sx={{ ml: 1 }}>e_dn.</Typography>
        </Box>
      </Box>

      {/* Okno dialogowe pomocy */}
      <Dialog open={helpOpen} onClose={handleHelpClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          Help
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Welcome to the Help Section!
        </Typography>
        <Typography variant="body1" paragraph>
          Here you can find information on how to use the application, common issues, and more.
        </Typography>
        <Typography variant="body2">
          <strong>Firstly you have to know how flag submiting works.</strong>
          <br />
          In such panel as below you have to enter your flag and choose the task you have received your flag from and submit:
          <br />
          <br />
        </Typography>
        <Box sx={{ mb: 2 }}>
          <center>
        <img src={Zglaszanie1} alt="Example" style={{ width: '50%', borderRadius: '8px' }} />
        <br />
        <img src={Zglaszanie2} alt="Example" style={{ width: '50%', borderRadius: '8px' }} />
        </center>
        </Box>
        
        <Typography variant="body2">
          After submitting correct flag you have to complete survey to help me with Inżynier xD, as below:
          <br />
          <br />
        </Typography>
        <Box sx={{ mb: 2 }}>
          <center>
          <img src={Survey1} alt="Example" style={{ width: '50%', borderRadius: '8px' }} />
        </center>
        </Box>
        <Typography variant="body2" paragraph>
          <strong>Filling as below:</strong>
          <ul>
            <li>Time Taken (minutes) - Report only minutes you have spent doing tasks to obtain your flag</li>
            <li>Difficulty - Choose one of the options from dropdown which describes how hard was task</li>
            <li>How have you captured flag - Describe step by step how you obtain your flag (you can use shortcuts or non formal language just let me know how you did it)</li>
            <li>Comment - Anything you want to share with me about this task or even about your life :)</li>
          </ul>
          <strong>Example of filled survey:</strong>
          <br />
        </Typography>
        <Box sx={{ mb: 2 }}>
          <center>
          <img src={Survey2} alt="Example" style={{ width: '50%', borderRadius: '8px' }} />
        </center>
        </Box>
        <Typography variant="body2">
          After submitting flags your actual status with <Icon><HourglassEmptyOutlined></HourglassEmptyOutlined></Icon> should change to <Icon><CheckCircle></CheckCircle></Icon> as below:
          <br />
          <br />
        </Typography>
        <Box sx={{ mb: 2 }}>
          <center>
        <img src={Flagi1} alt="Example" style={{ width: '80%', borderRadius: '8px' }} />
        <br />
        <img src={Flagi2} alt="Example" style={{ width: '80%', borderRadius: '8px' }} />
        </center>
        </Box>
        <Typography variant="body1" paragraph>
          <strong>To add some spice and make you feel like you're in a real CTF competition you can track your score, which will be made public in the last few days (with the consent of the participants, of course) and the top 3 people can count on modest rewards for their help :)</strong>
        </Typography>
        <Box sx={{ mb: 2 }}>
          <center>
        <img src={Panel1} alt="Example" style={{ width: '60%', borderRadius: '8px' }} />
        <br />
        <img src={Panel2} alt="Example" style={{ width: '60%', borderRadius: '8px' }} />
        </center>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubmitFlag;
