import React, { useState } from 'react';
import Leaderboard from './Leaderboard';
import { Container, Box, Typography } from '@mui/material';

const AdminDashboard = () => {
  const [tab, setTab] = useState(0);

  return (
    <Container>
      <Box sx={{ width: '100%' }}>
        <Leaderboard />
      </Box>
    </Container>
  );
};

export default AdminDashboard;
