// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#800080', // Purple
    },
    secondary: {
      main: '#f50057', // Optional: You can set a secondary color if needed
    },
    success: {
      main: '#4caf50', // Green, adjust if needed
    },
    warning: {
      main: '#ff9800', // Orange, adjust if needed
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#800080', // Default color for IconButton
        },
      },
    },
  },
});

export default theme;
