import React, { useState, useEffect } from 'react';
import { getLeaderboard, removeTask, addUser } from '../api'; // Import funkcji
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, IconButton, Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { saveAs } from 'file-saver'; // Użyj biblioteki do pobierania plików

const Leaderboard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newUser, setNewUser] = useState({ name: '', token: '', score: '' });
  const [addUserError, setAddUserError] = useState(null);
  const [addUserSuccess, setAddUserSuccess] = useState(null);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getLeaderboard();
        if (result && Array.isArray(result.leaderboard)) {
          setData(result.leaderboard); // Zmieniono z result.results na result.leaderboard
        } else {
          setError('Invalid data format received.');
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setError('Forbidden: You do not have access to the admin area.');
        } else {
          setError('An error occurred while fetching the leaderboard.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleRemoveTask = async (taskId, playerId) => {
    try {
      await removeTask(taskId, playerId);
      setData(data.filter(item => item.task_id !== taskId || item.player_id !== playerId));
    } catch (error) {
      console.error('Failed to remove task', error);
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setAddUserError(null);
    setAddUserSuccess(null);
    try {
      await addUser({
        name: newUser.name,
        token: newUser.token,
        score: newUser.score ? parseInt(newUser.score) : 0, // Ustaw score na 0 jeśli jest puste
      });
      setAddUserSuccess(`User ${newUser.name} added successfully!`);
      setNewUser({ name: '', token: '', score: '' });
    } catch (error) {
      setAddUserError(error.response?.data?.detail || 'Failed to add user.');
    }
  };

  const exportToCSV = () => {
    const csvRows = [];
    // Add headers
    csvRows.push(['Player ID', 'Player Name', 'Task ID', 'Points', 'Time Taken', 'Difficulty', 'Completed At', 'Description', 'Comment'].join(','));

  
    // Add data rows
    data.forEach(row => {
      csvRows.push([
        row.player_id,
        row.player_name,
        row.task_id,
        row.points,
        row.time_taken,
        row.difficulty,
        row.completed_at,
        row.description,
        row.comment
      ].join(','));
    });

    // Create a CSV Blob and save it
    const csvBlob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
    saveAs(csvBlob, 'leaderboard.csv');
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center'
        }}
      >
        <Typography variant="h4" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        <br />
        Leaderboard
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={exportToCSV}
        sx={{ marginBottom: '16px' }}
      >
        Export to CSV
      </Button>
      {/* Formularz do dodawania nowego użytkownika */}
      <Box component="form" onSubmit={handleAddUser} sx={{ marginBottom: '16px' }}>
        <Typography variant="h6">Add New User</Typography>
        <TextField
          label="Name"
          name="name"
          value={newUser.name}
          onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          required
          fullWidth
          sx={{ marginBottom: '8px' }}
        />
        <TextField
          label="Token"
          name="token"
          value={newUser.token}
          onChange={(e) => setNewUser({ ...newUser, token: e.target.value })}
          required
          fullWidth
          sx={{ marginBottom: '8px' }}
        />
        <TextField
          label="Score (Optional)"
          name="score"
          type="number"
          value={newUser.score}
          onChange={(e) => setNewUser({ ...newUser, score: e.target.value })}
          fullWidth
          sx={{ marginBottom: '8px' }}
        />
        {addUserError && <Typography color="error">{addUserError}</Typography>}
        {addUserSuccess && <Typography color="primary">{addUserSuccess}</Typography>}
        <Button variant="contained" color="secondary" type="submit">Add User</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Player ID</TableCell>
              <TableCell>Player Name</TableCell>
              <TableCell>Task ID</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Time Taken</TableCell>
              <TableCell>Difficulty</TableCell>
              <TableCell>Completed At</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.player_id}</TableCell>
                  <TableCell>{row.player_name}</TableCell>
                  <TableCell>{row.task_id}</TableCell>
                  <TableCell>{row.points}</TableCell>
                  <TableCell>{row.time_taken}</TableCell>
                  <TableCell>{row.difficulty}</TableCell>
                  <TableCell>{new Date(row.completed_at).toLocaleString()}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.comment}</TableCell>
                  <TableCell>
                    <IconButton 
                      color="error" 
                      onClick={() => handleRemoveTask(row.task_id, row.player_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={10} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Leaderboard;
