import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, Typography, CircularProgress, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; 
import Login from './components/Login';
import SubmitFlag from './components/SubmitFlag';
import Profile from './components/Profile';
import { getProfile } from './api';
import AdminDashboard from './components/AdminDashboard';
import Cookies from 'js-cookie';

const WEBSOCKET_URL = "wss://api.dndot.cloud/ws";
const RECONNECT_INTERVAL = 5000;

const App = () => {
  const [player, setPlayer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [socket, setSocket] = useState(null);
  const [resetMessage, setResetMessage] = useState(''); 
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [voteStatus, setVoteStatus] = useState({}); // Stan głosowania
  const [votePromptOpen, setVotePromptOpen] = useState(false);  // Nowy stan dialogu do głosowania
  const [currentMachine, setCurrentMachine] = useState(null);    // Przechowywanie informacji o maszynie
  const [countdown, setCountdown] = useState(15); // Czas odliczania w sekundach
  const [voteTimer, setVoteTimer] = useState(null); // Przechowywanie timer'a

  const fetchProfile = async () => {
    setLoading(true);
    try {
      const data = await getProfile();
      setProfileData(data);
    } catch (error) {
      console.error("Failed to fetch profile", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (player) {
      const accessToken = Cookies.get('wsToken');
      console.log("Creating WebSocket connection");

      const createWebSocket = () => {
        const ws = new WebSocket(WEBSOCKET_URL + "?wsToken=" + accessToken);
        setSocket(ws);

        ws.onopen = () => {
          console.log("WebSocket connection opened");
        };

        ws.onclose = () => {
          console.log("WebSocket connection closed");
          setTimeout(createWebSocket, RECONNECT_INTERVAL);
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
        
          if (message.action === 'prompt_vote') {
            setCurrentMachine(message.machine_id);
            setVotePromptOpen(true);  // Otwórz okno dialogowe do głosowania
            startCountdown(); // Rozpocznij odliczanie po otrzymaniu promptu
          } else if (message.action === 'vote_update') {
            setVoteStatus({
              machine_id: message.machine_id,
              yes_votes: message.yes_votes,
              no_votes: message.no_votes,
              required_votes: message.required_votes
            });
          } else if (message.action === 'vote_end') {
            setSnackbarOpen(true);
            setResetMessage(message.message);
            resetVotes(); // Zresetuj głosy po zakończeniu głosowania
          }
        };
      };

      createWebSocket();

      return () => {
        if (socket) {
          socket.close();
        }
        if (voteTimer) {
          clearInterval(voteTimer); // Oczyść timer, gdy komponent jest odmontowywany
        }
      };
    }
  }, [player]);

  // Funkcja resetująca głosy
  const resetVotes = () => {
    setVoteStatus({});  // Resetowanie głosów
  };

  const sendVoteMessage = (machineId, vote) => {
    if (socket) {
      const message = {
        action: 'cast_vote',
        machine_id: machineId,
        vote: vote,
        player_id: player?.id
      };
      socket.send(JSON.stringify(message));
      setVotePromptOpen(false); // Zamknij okno dialogowe po oddaniu głosu
      clearInterval(voteTimer); // Zatrzymaj timer po oddaniu głosu
    } else {
      console.error("WebSocket not connected");
    }
  };

  const startVoting = (machineId) => {
    if (socket) {
      const message = {
        action: 'vote_reset',
        machine_id: machineId,
        player_id: player?.id
      };
      socket.send(JSON.stringify(message));
    } else {
      console.error("WebSocket not connected");
    }
  };

  // Funkcja do rozpoczęcia odliczania czasu głosowania
  const startCountdown = () => {
    setCountdown(15);
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(timer);
          setVotePromptOpen(false); // Zamknij okno głosowania po 15 sekundach
        }
        return prev - 1;
      });
    }, 1000); // Co sekundę
    setVoteTimer(timer);
  };

  // Obliczanie procentu postępu
  const yesVotes = voteStatus.yes_votes || 0;
  const noVotes = voteStatus.no_votes || 0;
  const requiredVotes = voteStatus.required_votes || 0;
  const totalVotes = yesVotes + noVotes;
  const voteProgress = requiredVotes > 0 ? (totalVotes / requiredVotes) * 100 : 0;

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: '#f5f5f5' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, bgcolor: '#f5f5f5' }}>
            <Routes>
              <Route path="/" element={player ? (
                <React.Fragment>
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
                    <SubmitFlag onTaskUpdate={fetchProfile} />
                  </Box>
                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 2 }}>
                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Profile profile={profileData} onVoteReset={(machineId) => startVoting(machineId)} voteStatus={voteStatus} />
                    )}
                  </Box>
                </React.Fragment>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                  <Typography variant="h2">CTF Inżynier</Typography>
                  <Login onLogin={(playerData) => {
                    setPlayer(playerData);
                    fetchProfile();
                  }} />
                </Box>
              )} />
              <Route path="/admin" element={<AdminDashboard />} />
            </Routes>
          </Box>
        </Box>

        {/* Okno głosowania */}
        <Dialog open={votePromptOpen} onClose={() => { 
          setVotePromptOpen(false); 
          resetVotes();  // Resetujemy głosy po zamknięciu okna
        }}>
          <DialogTitle>Reset machine</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Do you want to reset {currentMachine}?</Typography>
            <Typography variant="body2" color="textSecondary">
              Pozostały czas: {countdown} sekund
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => sendVoteMessage(currentMachine, "yes")} color="primary">
              Tak
            </Button>
            <Button onClick={() => sendVoteMessage(currentMachine, "no")} color="secondary">
              Nie
            </Button>
          </DialogActions>

          {/* Progres głosowania */}
          {voteStatus.required_votes > 0 && (
            <Box sx={{ p: 2 }}>
              <LinearProgress variant="determinate" value={voteProgress} />
              <Typography variant="body2">
                {totalVotes} / {voteStatus.required_votes} votes
              </Typography>
            </Box>
          )}
        </Dialog>

        {/* Snackbar */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          message={resetMessage}
        />
      </Router>
    </ThemeProvider>
  );
};

export default App;
