import axios from 'axios';

const API_URL = 'https://api.dndot.cloud'; // Zamień na odpowiedni URL

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Umożliwia wysyłanie ciasteczek
});

export const login = async (token) => {
  const response = await api.post('/login', { token });
  return response.data;
};

export const submitFlag = async (taskId, _flag) => {
    const response = await api.post('/submit-flag', 
        { task_id: taskId, flag: _flag }, 
        { headers: { 'Content-Type': 'application/json' } });
    return response.data;
  };
  
export const getProfile = async () => {
  const response = await api.get('/me');
  return response.data;
};

export const getLeaderboard = async () => {
  const response = await api.get('/admin/leaderboard');
  return response.data;
};

export const getTasks = async () => {
    const response = await axios.get(`${API_URL}/admin/tasks`);
    return response.data;
  };
  
export const removeTask = async (taskId, playerId) => {
    try {
      const response = await api.delete('/admin/remove-task', {
        params: { task_id: taskId, player_id: playerId }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to remove task', error);
      throw error;
    }
  };


  export const updateTaskDetails = async (details) => {
    console.log('Details to update:', details);  // Log details to ensure correct data is being sent
    try {
      const response = await api.post('/update-task-details', details, {
        headers: { 'Content-Type': 'application/json' },
      });
      return response.data;
    } catch (error) {
      console.error('Error updating task details:', error.response ? error.response.data : error.message);
      throw error;
    }
  };


  export const addUser = async (userData) => {
    try {
      const response = await api.post('/admin/add-user', userData, {
        headers: { 'Content-Type': 'application/json' }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to add user', error);
      throw error;
    }
  };