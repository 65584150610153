import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, FormControl, InputLabel, Select, MenuItem, Button, Tooltip, Snackbar } from '@mui/material';
import { CheckCircle, HourglassEmpty, Info, Refresh, PlayArrow } from '@mui/icons-material';
import { updateTaskDetails } from '../api'; // Zakładając, że funkcja updateTaskDetails jest w pliku api.js

const Profile = ({ profile, onVoteReset, voteStatus }) => {
  const [openSurveyDialog, setOpenSurveyDialog] = useState(false);
  const [timeTaken, setTimeTaken] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [description, setDescription] = useState('');
  const [comment, setComment] = useState('');
  const [taskId, setTaskId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (profile) {
      if (!profile.survey_filled) {
        setTaskId(profile.last_completed_task || ''); // Set taskId from profile
        setOpenSurveyDialog(true);
      }
    }
  }, [profile]);

  const handleCloseDialog = async () => {
    try {
      // Walidacja danych przed wysłaniem do API
      if (!timeTaken.trim()) {
        setErrorMessage('Time taken cannot be empty');
        setSnackbarOpen(true);
        return;
      }
      if (!difficulty.trim()) {
        setErrorMessage('Difficulty cannot be empty');
        setSnackbarOpen(true);
        return;
      }
      if (!description.trim()) {
        setErrorMessage('Description cannot be empty');
        setSnackbarOpen(true);
        return;
      }

      // Przygotowanie szczegółów do aktualizacji
      const details = {
        task_id: taskId, // Map taskId to task_id
        time_taken: parseInt(timeTaken, 10) || 0, // Convert timeTaken to number and set default to 0
        difficulty, // Map difficulty directly
        description, // Map description directly
        comment, // Map comment directly
      };

      await updateTaskDetails(details); // Call the function to update task details
      setOpenSurveyDialog(false); // Close the dialog if update is successful
    } catch (error) {
      setErrorMessage('Failed to update task details. Please try again.');
      setSnackbarOpen(true);
      console.error('Failed to update task details:', error);
    }
  };

  const handleDialogClose = (event, reason) => {
    // Prevent closing when clicking outside or pressing Escape
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setOpenSurveyDialog(false);
  };

  const formatCompletionDate = (dateString) => {
    if (!dateString) return 'Not completed';
    const date = new Date(dateString);
    return `${date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} at ${date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`;
  };

  const totalPoints = profile.tasks.reduce((sum, task) => sum + (task.completed ? task.points : 0), 0);

  return (
    <Box sx={{ p: 3, width: '100%' }}>
      <Typography component="h1" variant="h5" gutterBottom>
        {profile.player_name}'s Profile
      </Typography>
      <Box sx={{ mb: 3, p: 3, border: '1px solid #967bb6', borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>
          Last Captured Flag
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '0.875rem' }}>
          {profile.last_completed_task || 'None'}
        </Typography>
      </Box>

      <Box sx={{ mb: 3, p: 3, border: '1px solid #967bb6', borderRadius: 2, boxShadow: 1 }}>
        <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem' }}>
          Total Points
        </Typography>
        <Typography variant="h5" sx={{ fontSize: '1.25rem' }}>
          {totalPoints}
        </Typography>
      </Box>
      <Divider sx={{ mb: 3, bgcolor: '#967bb6'}} />
      <Typography variant="h6" gutterBottom>
        All flags
      </Typography>
      <List sx={{ width: '100%' }}>
        {profile.tasks.map((task) => (
          <ListItem key={task.task_id} sx={{ p: 2, borderBottom: '1px solid #967bb6', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexGrow: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {task.task_id}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  Points: {task.points}
                </Typography>
                <Typography variant="body2">
                  Completed at: {formatCompletionDate(task.completed_at)}
                </Typography>
              </Box>
              <IconButton color={task.completed ? 'success' : 'warning'} sx={{ ml: 2 }}>
                {task.completed ? <CheckCircle /> : <HourglassEmpty />}
              </IconButton>
              <Tooltip title="Reset machine">
                  <IconButton onClick={() => onVoteReset(task.task_id)}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Go to task">
          <IconButton onClick={() => window.open(task.url, '_blank')}>
            <PlayArrow />
          </IconButton>
        </Tooltip>
            </Box>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={openSurveyDialog}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Update Task Details for {taskId}<br /><body>You cannot report other flags before doing it :(</body> </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              autoFocus
              margin="dense"
              id="timeTaken"
              label="Time Taken (minutes)"
              type="number"
              fullWidth
              variant="outlined"
              value={timeTaken}
              onChange={(e) => setTimeTaken(e.target.value)}
              sx={{ flexGrow: 1, mr: 1 }}
            />
            <Tooltip title="Enter the total time you spent capturing this flag. Include only time spent with this flag, do not include time spent on other tasks." arrow>
              <IconButton>
                <Info color="action" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FormControl fullWidth margin="dense" sx={{ flexGrow: 1, mr: 1 }}>
              <InputLabel id="difficulty-label">Difficulty</InputLabel>
              <Select
                labelId="difficulty-label"
                id="difficulty"
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                label="Difficulty"
              >
                <MenuItem value="Easy">Easy</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="Hard">Hard</MenuItem>
                <MenuItem value="Extreme">Extreme</MenuItem>
              </Select>
            </FormControl>
            <Tooltip title="Select the difficulty level of capturing this flag." arrow>
              <IconButton>
                <Info color="action" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              margin="dense"
              id="description"
              label="How have you captured flag"
              type="text"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ flexGrow: 1, mr: 1 }}
            />
            <Tooltip 
              title={
                <Typography variant="body3">
                  Provide details on how you captured the flag, step by step<br />
                  Example:<br />
                  1. Describe the first step.<br />
                  2. Describe the next step.
                </Typography>
              } 
              arrow
              enterTouchDelay={0}
              placement="top"
            >
              <IconButton>
                <Info color="action" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              margin="dense"
              id="comment"
              label="Comment"
              type="text"
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              sx={{ flexGrow: 1, mr: 1 }}
            />
            <Tooltip title="Add any additional comments about the task." arrow>
              <IconButton>
                <Info color="action" />
              </IconButton>
            </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for error messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={errorMessage}
      />
    </Box>
  );
};

export default Profile;
